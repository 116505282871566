import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
        enteredWeb: "",
        playingAudio: "",
    }

    const getters = {
        enteredWeb: (state) => state.enteredWeb,
        playingAudio: (state) => state.playingAudio,
    }

    const mutations = {
        SET_entered(state, value) {
            state.enteredWeb = value
        },
        SET_audio(state, value) {
            state.playingAudio = value
        }
    }

    const actions = {
        setEntered(context, value) {
            context.commit("SET_entered", value)
        },
        setAudio(context, value) {
            context.commit("SET_audio", value)
        },
    }

export {state, getters, mutations, actions}
