<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>

</template>
<script>

export default {}
</script>

<style>

@font-face {
  font-family: 'Rubik';
  src: url('/fonts/Rubik-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'RubikLight';
  src: url('/fonts/Rubik-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Rubik-Italic';
  src: url('/fonts/Rubik-Italic.ttf') format('ttf');
}


@font-face {
  font-family: 'Rubik-Medium';
  src: url('/fonts/Rubik-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Rubik-Bold';
  src: url('/fonts/Rubik-Bold.ttf') format('ttf');
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  z-index: 94;
}

#app {
  font-family: 'Rubik', sans-serif;
  font-size: 21px;
  line-height: 1.5;
  letter-spacing: -0.007em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.closeAbout{
  color: #ccc;
  float: right;
  font-size: 3rem;
  left: 0;
}

.closeAbout:hover,
.closeAbout:focus
{
  color: black;
  text-decoration: none;
  cursor: pointer;
}

</style>
