import Vue from 'vue'
import VueRouter from 'vue-router'
import responsiveRoute from './responsive-route.js'


Vue.use(VueRouter)

const routes = [
/*    {
        path: '/',
        name: 'Home',
        component: () => import(/!* webpackChunkName: "Landing" *!/ '../views/Home.vue')
    },
    {
        path: '/mobile',
        name: 'HomeMobile',
        component: () => import(/!* webpackChunkName: "Landing" *!/ '../views/HomeMobile.vue')
    },*/
    {
        path: '/',
        name: 'Home',
        component: responsiveRoute({
            xs: () => import(/* webpackChunkName: "Explore-mobile" */ '../views/HomeMobile.vue'),
            lg: () => import(/* webpackChunkName: "Explore" */ '../views/Home.vue')

        })
    },

    {
        path: '/production-sound',
        name: 'ProductionSound',
        component: () => import(/* webpackChunkName: "Projects" */ '../views/ProductionSound.vue')
    },
    {
        path: '/editorial-sound',
        name: 'EditorialSound',
        component: () => import(/* webpackChunkName: "Projects" */ '../views/EditorialSound.vue')
    },
    {
        path: '/music',
        name: 'Music',
        component: () => import(/* webpackChunkName: "Projects" */ '../views/Music.vue')
    },
    {
        path: '/projects',
        name: 'Projects',
        component: () => import(/* webpackChunkName: "Projects" */ '../views/Projects.vue')
    },
    {
        path: '/standaloneobject',
        name: 'StandaloneObject',
        component: () => import(/* webpackChunkName: "StandaloneObject" */ '../views/StandaloneObject.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
